<template>
  <div>
    <!-- pic02644613-58DB-4547-B601-9773F9A47C6D.jpg -->
    <img class="rounded leftimage" style="width: 200px; height: 150px" src="@/assets/news-network/lu-design-details-divulged.jpg">
    <p>
      For LEGO Universe design director Brian Booker, "work" means maximizing gaming fun. The LEGO Universe News Network recently gathered up some goodies about Brian and his team's gameplay solutions; Read on to get 'em!
    </p>
    <p>
      Brian also stars in this update's further behind the scenes bonus feature, where he dishes out details about a popular LEGO Universe forum topic: The game’s currency.
    </p>
    <p>
      <br><b>Hello Brian. Tell us a bit about your role in LEGO Universe's development.</b>
    </p>
    <p>
      First and foremost it's my job to ensure that people will have a great time when they log into LEGO Universe. I’m crafting the game's features on a high level with a focus on the kinds of experiences players will have. I also guide the entire design team.
    </p>
    <p>
      <b>Do you play with LEGO?</b>
    </p>
    <p>
      I've played with LEGO since I was a kid. My current favorites are the Castle and Star Wars® sets… Do I get a free death Death Star for doing this interview?
    </p>
    <p>
      <b>What are your interests outside of LEGO Universe?</b>
    </p>
    <p>
      I love playing all kinds of sports - most recently roller hockey and basketball. I enjoy exploring the mountains and traveling. I’m also a fan of science fiction, fantasy, metaphysics and the "unknown."
    </p>
    <p>
      <b>Are you a gamer?</b>
    </p>
    <p>
      (Laughs) I'm totally a hardcore gamer! I've been into role-playing games and board games since I was 10 years old. Back then, my friends and I would go to the local library to play the original Dungeons and Dragons® game. I also played the very first video games, like Pong® and Space Invaders® , and the very first MMOG's. I’ve never stopped gaming since I was a kid, and I've been designing games for about as long as I've been playing them.
    </p>
    <p>
      <b>What do you like about designing games?</b>
    </p>
    <p>
      It’s just very powerful to develop an idea into reality. I can also be creative and tell a story, and hopefully my work allows thousands of people to have fun and enjoy themselves. So to me, it's an ideal way to spend my time.
    </p>
    <p>
      <b>Is a game designer's focus on the look of the game?</b>
    </p>
    <p>
      No, my team’s responsibility is the gameplay.
    </p>
    <!-- picECB990A3-B168-4561-853D-46AFA937F358.jpg -->
    <img class="rounded rightimage" style="width: 200px; height: 152px;" src="@/assets/news-network/lu-design-details-divulged-2.jpg">

    <p>
      <b>How do you design “gameplay”?</b>
    </p>
    <p>
      By providing entertaining answers to questions like these: When you create your minifig and enter LEGO Universe, what do you see? What point of view do you have in the game? What can you do as your minifig? What’s the story of the game; what’s the fiction behind it? What kinds of social interactions can players have with each other? How do players interact with the game from a control standpoint?
    </p>
    <p>
      “Gameplay” is the experience that results from my team bringing all of these elements together-- and it’s our primary concern that it be a fun experience!
    </p>
    <p>
      So, your team is kind of like the fun police!
    </p>
    <p>
      (Laughs) I like that! In fact I might start wearing a little law enforcement badge that says “FUN” on it!
    </p>
    <p>
      <b>Do you create LEGO Universe’s minigames?</b>
    </p>
    <p>
      Yes, that’s part of my team’s responsibility.
    </p>
    <p>
      <b>Does LEGO Universe include a fantastic story?</b>
    </p>
    <p>
      Yes! My team has collaborated on an overarching epic storyline about the origins of LEGO Universe and the minifigs that inhabit it.
    </p>
    <p>
      I can’t tell everything about the game just yet, but maybe I can give you some hints… Aside from just playing games or trading and socializing, the story allows minifigs to team up—and that’s when the real adventuring begins. If the teams of little LEGO people are successful in their endeavors, they’ll uncover new zones where they can have new and different experiences…
    </p>
    <p>
      That’s all I can say for now, but I’m really excited for the time when I can share more details with everyone!
    </p>
    <p>
      <b>Which of the game’s features are you most excited about?</b>
    </p>
    <p>
      I’d say the systems that allow users to generate their own content—things like original, highly customizable creatures as well as static models. It’s just amazing to be able to build your own creation, deploy it and have it come to life in the game world, and then show it off to your friends.
    </p>
  </div>
</template>
